import React, { Component } from "react";

function Home() {
	return (
		<div>
			<h1>This is the "Home" page after a successful login</h1>
			<h2>Available routes are:</h2>
			<ol type="1">
				<li>
					<code>/patientSearch</code>
				</li>
				<li>
					<code>/newPatientForm</code>
				</li>
				<li>
					<code>/history/:id</code>
				</li>
				<li>
					<code>/termsAndConditions</code>
				</li>
			</ol>
			<h2>Questions for Laura</h2>
			<ol type="1">
				<li>
					What is this page supposed to be according to this workflow?
				</li>
			</ol>
		</div>
	);
}

export default Home;
