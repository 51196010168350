import React, { Component } from 'react';
import { Form, Col, Button } from 'react-bootstrap';

class NewPatientForm extends Component {
  state = {
    timeStamp: new Date().toLocaleString(),
  }

  render() {
    return (
      <div style={{ backgroundColor: '#e9f6ff' }}>
        <Form>
          <div class="container rounded-lg bg-white mt-3 mb-3 pt-4 pb-4">
            <h5>Create New Patient</h5>
            <div class="containter p-3">
              <Form.Row>
                <Col>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="email" placeholder="-" />
                </Col>

                <Col>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="password" placeholder="-" />
                </Col>

                <Col>
                  <Form.Label>Date of Birth</Form.Label>
                  <Form.Control type="date" />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col xs={3}>
                  <Form.Label>First Name</Form.Label>
                  <Form.Control type="email" placeholder="-" />
                </Col>

                <Col xs={3}>
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control type="password" placeholder="-" />
                </Col>

                <Col xs={6}>
                  <Form.Label>Sex</Form.Label>
                  {['radio'].map((type) => (
                    <div key={`custom-inline-${type}`} className="mb-3">
                      <Form.Check
                        custom
                        inline
                        label="Male"
                        type={type}
                        id={`custom-inline-${type}-1`}
                      />
                      <Form.Check
                        custom
                        inline
                        label="Female"
                        type={type}
                        id={`custom-inline-${type}-2`}
                      />
                      <Form.Check
                        custom
                        inline
                        label="Intersex"
                        type={type}
                        id={`custom-inline-${type}-3`}
                      />
                    </div>
                  ))}
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label>Country of Origin</Form.Label>
                  <Form.Control as="select" defaultValue="Choose...">
                    <option>Select...</option>
                    <option>...</option>
                  </Form.Control>
                </Col>

                <Col>
                  <Form.Label>Primary Language</Form.Label>
                  <Form.Control as="select" defaultValue="Choose...">
                    <option>Select...</option>
                    <option>...</option>
                  </Form.Control>
                </Col>

                <Col>
                  <Form.Label>Secondary Language</Form.Label>
                  <Form.Control as="select" defaultValue="Choose...">
                    <option>Select...</option>
                    <option>...</option>
                  </Form.Control>
                </Col>
              </Form.Row>
            </div>
          </div>
          <div class="container rounded-lg bg-white mt-3 mb-3 pt-4 pb-4">
            <h5>Contact & Location</h5>
            <div class="containter p-3">
              <Form.Row>
                <Col xs={4}>
                  <Form.Label>Place of Residence</Form.Label>
                  <Form.Control as="select" defaultValue="Choose...">
                    <option>Select...</option>
                    <option>Los Angeles, CA</option>
                    <option>New York, NY</option>
                    <option>Chicago, IL</option>
                  </Form.Control>
                </Col>
                <Col xs={8}>
                  <Form.Label>Address or Location Description</Form.Label>
                  <Form.Control type="text" placeholder="-" />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label>Email</Form.Label>
                  <Form.Control type="email" placeholder="-" />
                </Col>
                <Col>
                  <Form.Label>Phone</Form.Label>
                  <Form.Control type="tel" placeholder="+_ (___) ___-____" />
                </Col>
                <Col>
                  <Form.Label>Other Contact Method</Form.Label>
                  <Form.Control type="text" placeholder="-" />
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Label>Emergency Contact Name</Form.Label>
                  <Form.Control type="text" placeholder="-" />
                </Col>
                <Col>
                  <Form.Label>Emergency Contact Information</Form.Label>
                  <Form.Control type="text" placeholder="-" />
                </Col>
                <Col>
                  <Form.Label>Relation of Contact to Patient</Form.Label>
                  <Form.Control type="text" placeholder="-" />
                </Col>
              </Form.Row>
            </div>
          </div>
        </Form>
        <div class="container rounded-lg mt-3 mb-3 pt-4 pb-4">
          <div className="float-left">
            <p>Created on: <b>{this.state.timeStamp}</b></p>
            <p>Created by: <b>User's Name / Health Organization Name</b></p>
          </div>
          <div className="float-right">
            <Button size="lg"> Add Patient </Button>
          </div>
        </div>
      </div>
    );
  }
}

export default NewPatientForm;