import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LoginButton = () => {
  const { isLoading, loginWithRedirect, isAuthenticated } = useAuth0();
  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    (!isLoading) && (
      <button onClick={() => loginWithRedirect()}>
        Log In
      </button>
    )
  )
};

export default LoginButton;