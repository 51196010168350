import React, { Component } from "react";
import DataTable from "react-data-table-component";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Spinner from "react-bootstrap/Spinner";
import { Col, Container, Form, Button } from "react-bootstrap";

import PatientSearchError from "./PatientSearchError";
import PatientSearchForm from "./PatientSearchForm";

const columns = [
	{
		name: "ID",
		selector: "id",
		sortable: true,
	},
	{
		name: "First Name",
		selector: "firstName",
		sortable: true,
	},
	{
		name: "Last Name",
		selector: "lastName",
		sortable: true,
	},
	{
		name: "DOB",
		selector: "birthDate",
		sortable: true,
	},
	{
		name: "Age",
		selector: "birthDate",
		sortable: true,
		right: true,
	},
	{
		name: "Sex",
		selector: "sex",
		sortable: true,
		right: true,
	},
	{
		name: "Case ID No.",
		selector: "asylumId",
		sortable: true,
		right: true,
	},
	{
		name: "Last Visit",
		selector: "updatedAt",
		sortable: true,
		right: true,
	},
];

class PatientSearch extends Component {
	constructor() {
		super();
		this.state = {
			error: null,
			data: null,
		};

		this.handleRowClick = this.handleRowClick.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleRowClick = (rowData) => {
		//try console logging rowData to get the ID of the patient that was clicked
		alert(
			`Clicking this will redirect to ${rowData.firstName}'s patient page`
		);
	};

	handleSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);
		const formDataObj = Object.fromEntries(formData.entries());
		const query = formDataObj.searchQuery;

		try {
			const url =
				process.env.REACT_APP_EHR_API_URL +
				"patient/patientSearch?searchQuery=" +
				query;
			const response = await fetch(url);
			const data = await response.json();
			this.setState({
				error: false,
				data: data,
			});
		} catch (error) {
			console.log(error);
			this.setState({
				error: true,
			});
		}
	};

	async initTable() {
		try {
			const url = process.env.REACT_APP_EHR_API_URL + "patient/recent";
			const response = await fetch(url);
			const data = await response.json();
			this.setState({
				error: false,
				data: data,
			});
		} catch (error) {
			console.log(error);
			this.setState({
				error: true,
			});
		}
	}

	componentDidMount() {
		this.initTable();
	}

	render() {
		const { error, data } = this.state;

		var tableArea;

		if (error) {
			tableArea = <PatientSearchError />;
		} else if (data) {
			tableArea = (
				<DataTable
					columns={columns}
					data={data.patients}
					striped={true}
					highlightOnHover={true}
					pointerOnHover={true}
					onRowClicked={this.handleRowClick}
					defaultSortField={"updatedAt"}
					pagination={true}
					noHeader={true}
				/>
			);
		} else {
			tableArea = <Spinner animation="border"></Spinner>;
		}

		return (
			<Container fluid>
				<Row>
					<Col>
						<Card className="shadow mt-4 p-4">
							<PatientSearchForm
								handleSubmit={this.handleSubmit}
							/>
							<Card.Body className="text-center">
								{tableArea}
							</Card.Body>
						</Card>
					</Col>
				</Row>
			</Container>
		);
	}
}
export default PatientSearch;
