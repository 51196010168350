import React, { Component } from 'react';
import Field from './Field';
import { Container, Row, Col, ListGroup, Table } from 'react-bootstrap';

const medicalhistory = {
    subtitle: {
        fontSize: '20px',
        fontWeight:900,
    },
    text: {
        color:'blue'
    }
}
class MedicalHistory extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Container>
                <Row>
                    <div style={medicalhistory.subtitle}>Medical History</div><button>Add</button>
                    <Table  bordered  size="med">
                    <thead>
                        <tr>
                        <th style={medicalhistory.text}>Current</th>
                        <th style={medicalhistory.text}>Resolved</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Ashtma</td>
                        <td>Polio</td>
                        </tr>
                        <tr>
                        <td>Polio</td>
                                    <td>Polio</td>

                        </tr>
                        <tr>
                            <td>3</td>
                            <td>covid</td>

                        </tr>
                        <tr>
                            <td>polio</td>

                        </tr>
                    </tbody>
                    </Table>

                    </Row>

                <Row>
                    <div style={medicalhistory.subtitle}>Medications</div>
                    <Table  bordered  size="med">
                    <thead>
                        <tr>
                        <th style={medicalhistory.text}>Current</th>
                        <th style={medicalhistory.text}>Resolved</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                        <td>Ashtma</td>
                        <td>Polio</td>
                        </tr>
                        <tr>
                        <td>Polio</td>
                                    <td>Polio</td>

                        </tr>
                        <tr>
                            <td>3</td>
                            <td>covid</td>

                        </tr>
                        <tr>
                            <td>polio</td>

                        </tr>
                    </tbody>
                    </Table>

                    </Row>
                    <Row>
                        <div style={medicalhistory.subtitle}>Allergies</div>
                        <div>
                            <ListGroup horizontal>
                            <ListGroup.Item>Allergy</ListGroup.Item>
                            <ListGroup.Item>Allergy</ListGroup.Item>
                            <ListGroup.Item>Allergy</ListGroup.Item>
                            <ListGroup.Item>Allergy!</ListGroup.Item>
                            </ListGroup>
                        </div>

                </Row>

                <Row>

                            <div style={medicalhistory.subtitle}>Social/ Family History</div>
                         <Table>
                         <tbody>
                                <tr>

                                    <td>N/A</td>
                                </tr>
                                <tr>

                                    <td>Family trauma</td>
                                </tr>
                                <tr>
                                    <td>Lives alone</td>
                                </tr>

                            </tbody>
                         </Table>



                </Row>
                <Row>
                    <div style={medicalhistory.subtitle}>Vaccines</div>
                </Row>
                <Row>
                    <div style={medicalhistory.subtitle}>Documents</div>
                </Row>
                </Container>
            </div>
        );
    }
}

export default MedicalHistory;