import React from 'react';

const fieldStyle = {
    value: {
        borderBottom: '1px solid grey',
        height: '30px',
    }
}
const Field = ({ placeholder, value }) => (
    <div >
        <div>{placeholder}</div>
        <div style={fieldStyle.value}>{value }</div>
    </div>
);

export default Field;