import React from "react";
import { useAuth0 } from "@auth0/auth0-react"
import { Nav, NavDropdown } from "react-bootstrap"
import { BsChevronLeft } from "react-icons/bs"
import { RiFolderUserFill, RiBarChart2Line, RiUserSettingsFill, RiBankFill, RiLogoutBoxLine } from "react-icons/ri"
import { AiFillQuestionCircle } from "react-icons/ai"
import { GiHamburgerMenu } from "react-icons/gi"
import LogoutButton from './LogoutButton';

const MainNav = () => {
  const handleSelect = (eventKey) => alert(`selected ${eventKey}`);
  return (
    <Nav style={{ backgroundColor: "#3C64B1" }} variant="pills" activeKey="1" onSelect={handleSelect}>

      <Nav.Item className="mr-auto">
        <Nav.Link eventKey="2" title="Item">
          <span style={{ color: "white" }}><BsChevronLeft /> Previous </span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item className="ml-auto mr-auto">
        <Nav.Link eventKey="3" disabled>
          <span style={{ color: "white", fontSize:"110%" }}>Patient Charts</span>
        </Nav.Link>
      </Nav.Item>
      <NavDropdown className="ml-auto" title={<GiHamburgerMenu style={{ color: "White", fontSize: "150%" }} />} id="nav-dropdown">
        <NavDropdown.Item eventKey="4.1"><RiFolderUserFill style={{ color: "#3C64B1", fontSize: "150%" }} /> Patient Charts</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.2"><RiBarChart2Line style={{ color: "#3C64B1", fontSize: "150%" }} /> Data Analytics</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.3"><RiUserSettingsFill style={{ color: "#3C64B1", fontSize: "150%" }} /> Roles and Permissions</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.4"><RiBankFill style={{ color: "#3C64B1", fontSize: "150%" }} /> Billing</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.5"><AiFillQuestionCircle style={{ color: "#3C64B1", fontSize: "150%" }} /> Terms & Tutorials</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item eventKey="4.6"><RiLogoutBoxLine style={{ color: "#3C64B1", fontSize: "150%" }} /> <LogoutButton/></NavDropdown.Item>
      </NavDropdown>
    </Nav>
  );
};

function NavBar() {
  const { isAuthenticated } = useAuth0();

  return <div>{isAuthenticated && <MainNav />}</div>;
}

export default NavBar;