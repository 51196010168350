import React, { Component } from 'react';
import Demography from './Demography';
import MedicalHistory from './MedicalHistory';
import VisitHistory from './VisitHistory';

class PatientHistory extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        const patientId = this.props.match.params.id

        return (
            <div>
                <Demography patientID={patientId} />
                <MedicalHistory patientId={patientId} />
                <VisitHistory/>
            </div>
        );
    }
}

export default PatientHistory;