import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Login from "./LoginButton";
import Logout from "./LogoutButton";

export const AuthNav = () => {
	const { isAuthenticated } = useAuth0();

	return <div>{isAuthenticated ? <Logout /> : <Login />}</div>;

};