import React, { Component } from "react";
import { Col, Form, Button } from "react-bootstrap";

class PatientSearchForm extends Component {
	constructor(props) {
		super();
	}

	render() {
		return (
			<Form className="my-2" onSubmit={this.props.handleSubmit}>
				<h3>Patients</h3>
				<Form.Row className="align-items-center">
					<Col xs={12} md={true}>
						<Form.Control
							name="searchQuery"
							placeholder="Search by Name or Identification Number"
						/>
					</Col>

					<Col xs="auto" className="my-2">
						<Button variant="primary" type="submit">
							New Patient or Visit
						</Button>
					</Col>

					<Col xs="auto" className="my-2">
						<Button variant="secondary">Refresh</Button>
					</Col>
				</Form.Row>
			</Form>
		);
	}
}

export default PatientSearchForm;
