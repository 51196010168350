import React, { Component } from 'react';
import { Container, Row, Col, ListGroup, Table } from 'react-bootstrap';

class VisitHistory extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div>
                <Container>
                    <Row>Visit History</Row>
                </Container>


            </div>
        );
    }
}

export default VisitHistory;