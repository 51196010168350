import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import Home from "./components/Home";
import { useAuth0, isAuthenticated } from "@auth0/auth0-react";
import NavBar from "./components/NavigationBar/NavBar";
import PrivateRoute from "./routes/private-route";
import { AuthNav } from "./components/NavigationBar/AuthNav";
import NewPatientForm from "./components/NewPatientForm/NewPatientForm";
import "bootstrap/dist/css/bootstrap.min.css";
import PatientHistory from "./components/PatientHistory/PatientHistory";
import PatientSearch from "./components/PatientSearch/PatientSearch";
import TermsAndCons from "./components/TermsAndConditions/TermsAndCons";

function App() {
	const { user, isAuthenticated } = useAuth0();

	return (
		<div id="app" className="d-flex flex-column h-100">
			{/*Sticky NavBar at top of application */}
			{isAuthenticated && <NavBar />}

			{/*If the user is not authenticated display the Authentication Nav */}
			{!isAuthenticated && <AuthNav />}

			{/*If user is authenticated allow them to access routes */}
			{isAuthenticated && (
				<Switch>
					<Route exact path='/'>
						<Redirect to = "/termsAndConditions"/>
					</Route>
					<PrivateRoute
						path="/termsAndConditions"
						exact
						component={TermsAndCons}
					/>
					<PrivateRoute
						path="/history/:id"
						exact
						component={PatientHistory}
					/>
					<PrivateRoute
						path="/patientSearch"
						exact
						component={PatientSearch}
					/>
					<PrivateRoute
						path="/newPatientForm"
						exact
						component={NewPatientForm}
					/>
				</Switch>
			)}
		</div>
	);
}

export default App;
