import React, { Component } from 'react';
import Field from './Field';
import { Container, Row, Col } from 'react-bootstrap';


class Demography extends Component {
    constructor(props) {
        super(props);
    }
    render() {

        const id = this.props.patientID;
        return (
            <div>

                <Container>
                    <Row><div>Demography</div></Row>
                    <Row>
                        <Col>Patient Name{/* {this.props} */}</Col>
                        <Col>Gender{/* {this.props} */}</Col>
                        <Col> DOB : {/* {this.props} */}</Col>
                        <Col>AGE : {/* {this.props} */}</Col>
                        <button> Edit </button>
                    </Row>
                    <Row>
                        <Col>
                            <Field
                                placeholder="Asylum ID Number"
                                value={id}
                            />
                        </Col>
                        <Col>
                            <Field
                            placeholder="Police ID Number"
                            value=""
                            />
                        </Col>
                        <Col>
                            <Field
                                placeholder="Country of Origin"
                                value=""
                            />
                        </Col>
                        <Col>
                            <Field
                            placeholder="Languages"
                            value=""
                            />
                        </Col>

                    </Row>
                    <Row>
                        <Col>
                            <Field
                        placeholder="Place of Residence"
                        value=""
                            />
                        </Col>
                        <Col>
                            <Field
                            placeholder="Address or Location Description"
                            value=""
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Field
                            placeholder="Email"
                            value=""
                                    />
                        </Col>
                        <Col>
                            <Field
                            placeholder="Phone No."
                            value=""
                              />
                        </Col>
                        <Col>
                            <Field
                            placeholder="Other contact method"
                            value=""
                                    />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Field
                                placeholder="Emergency Contact Name"
                                value=""
                            />
                        </Col>
                        <Col>
                            <Field
                            placeholder="Emergency Contact Info"
                            value=""
                            />
                        </Col>
                        <Col>
                                <Field
                            placeholder="Relationship of Contact to Patient"
                            value=""
                            />
                        </Col>
                    </Row>



                </Container>

            </div>
        );
    }
}

export default Demography;